/* -------------------------------------------------------------------------- */
/*                           Datatables js for Application                    */
/* -------------------------------------------------------------------------- */

function applicationClicked(application) {
    console.log(`Application data: ${application}`);
  
    const statuses = [
      {
        name: 'INCOMPLETE',
        color: 'warning',
      },
      {
        name: 'CP+ CLOSED',
        color: 'primary',
      },
      {
        name: 'CML OFFERS',
        color: 'success',
      },
      {
        name: 'CML EXPIRED',
        color: 'danger',
      },
      {
        name: 'CP+ EXPIRED',
        color: 'danger',
      },
      {
        name: 'NO OFFERS',
        color: 'danger',
      },
      {
        name: 'CP+ OFFERS',
        color: 'primary',
      },
      {
        name: 'CML2 OFFERS',
        color: 'primary',
      },
      {
        name: 'CML CLOSED',
        color: 'success',
      },
      {
        name: 'CML2 CLOSED',
        color: 'success',
      },
      {
        name: 'HARD STOP',
        color: 'danger',
      },
      {
        name: 'SIGNING REQUIRED',
        color: 'warning',
      },
    ];
  
    console.log(`Statuses: ${statuses}`);
  
    const statusMatch = statuses.find((s) => s.name === application.status);
    const badgeColor = statusMatch ? statusMatch.color : 'default';
    const badgeElement = document.getElementById('application-status');
    badgeElement.innerText = application.status;
    badgeElement.className = '';
    badgeElement.className = `ml-3 badge badge-${badgeColor}`;
  
    // populate view
    document.getElementById('application-detail-name').innerText = application.consumer;
    document.getElementById('application-detail-address').innerText = application.address;
    document.getElementById('application-detail-phone').innerText = application.phone;
    document.getElementById('application-detail-email').innerText = application.email;
  
    document.getElementById('application-detail-financed-amount').innerText = application.financedAmount;
    document.getElementById(
      'application-detail-interest-rate'
    ).innerText = `${application.interestRate}%`;
    document.getElementById(
      'application-detail-apr'
    ).innerText = `${application.apr}%`;
    document.getElementById('application-detail-term').innerText = application.term;
    document.getElementById('application-detail-monthly-payment').innerText = application.monthlyPayment;
    document.getElementById('application-detail-down-payment').innerText = application.downPayment;
    document.getElementById('application-detail-customer-id').innerText = application.customerID;
    document.getElementById('dacid').value = application.customerID;
    document.getElementById('do_this').value = application.who_id;
    document.getElementById('who_id').value = application.who_id;
    document.getElementById('lss_view').style = 'display: none;';
    document.getElementById('lss_view').src = '';
  
    //style="width:100%; height:800px;"
    if (application.try === 'ally_detail') {
      document.getElementById('action').style.display = 'none';
      //alert("1...."+application.try)
    } else if (application.try === 'gafco_detail') {
      document.getElementById('action').style.display = 'none';
    } else if (application.try === 'get_lss.x') {
      document.getElementById('action').style.display = 'none';
      document.getElementById('lss_view').style = 'width:100%; height:800px;';
      document.getElementById(
        'lss_view'
      ).src = `choice.dbw?get_lss.x=go&do_this=${application.who_id}`;
      //alert("3...."+application.try)
    } else {
      document.getElementById('action').style.display = 'block';
      //alert("2...."+application.try)
    }
  
    if (application.try === 'ally_detail') {
      document.getElementById('addCID').style.display = 'block';
      //alert("1...."+application.try)
    } else if (application.try === 'gafco_detail') {
      document.getElementById('addCID').style.display = 'block';
      document.getElementById('lss_view').style = 'width:100%; height:800px;';
      document.getElementById(
        'lss_view'
      ).src = `choice.dbw?gafco_purchase_list=go&who_id=${application.who_id}`;
      //alert("3...."+application.try)
    } else if (application.try === 'get_lss.x') {
      document.getElementById('addCID').style.display = 'block';
      //alert("3...."+application.try)
    } else {
      document.getElementById('addCID').style.display = 'none';
      //alert("2...."+application.try)
    }
    //endif
    document.getElementById('try').name = application.try;
  
    // switch views
    document.getElementById('applications-table').style.display = 'none';
    document.getElementById('application-detail').style.display = 'block';
  }
  
  function backToApplicationsTable() {
    // switch views
    document.getElementById('application-detail').style.display = 'none';
    document.getElementById('applications-table').style.display = 'block';
  }
  
  export default [backToApplicationsTable, applicationClicked];
  