/* -------------------------------------------------------------------------- */
/*                                 step wizard                                */
/* -------------------------------------------------------------------------- */
/* eslint-disable */
const wizardInit = () => {
  const wizards = document.querySelectorAll(".theme-wizard");

  const tabPillEl = document.querySelectorAll(
    '#pill-tab2 [data-bs-toggle="pill"]'
  );

  const tabProgressBar = document.querySelector(".theme-wizard .progress");

  wizards.forEach((wizard) => {
    let tabToggleButtonEl = wizard.querySelectorAll("[data-wizard-step]");

    let inputEmail = wizard.querySelector("[data-wizard-validate-email]");
    let emailPattern = inputEmail.getAttribute("pattern");

    let inputSSN = wizard.querySelector("[data-wizard-validate-ssn]");
    let ssnPattern = inputSSN.getAttribute("pattern");
    let inputConfirmSSN = wizard.querySelector(
      "[data-wizard-validate-confirm-ssn]"
    );
    let confirmSSNPattern = inputConfirmSSN.getAttribute("pattern");

    // let inputIssuanceDate = wizard.querySelector("#idate");
    // let issuanceDatePattern = inputIssuanceDate.getAttribute("pattern");

    // let inputExpirationDate = wizard.querySelector("#exp");

    let firstName = wizard.querySelector("#fname");
    let lastName = wizard.querySelector("#lname");

    let birthMonth = wizard.querySelector("#bmonth");
    let birthDay = wizard.querySelector("#bday");
    let birthYear = wizard.querySelector("#byear");

    let grossIncomeField = wizard.querySelector("#a_income");
    let purchaseCostField = wizard.querySelector("#med_cost");
    let serviceDateField = wizard.querySelector("#tdate");

    let choiceDisclosure = wizard.querySelector("#affirm1");
    let fcraDisclosure = wizard.querySelector("#affirm2");

    let formSelfId = wizard.querySelector("#self-id");
    let formContactInfo = wizard.querySelector("#contact-info");
    let formPurchaseInfo = wizard.querySelector("#purchase-info");

    let nextButton = wizard.querySelector(".next button");
    let nextButtonParent = wizard.querySelector(".next");

    let prevButton = wizard.querySelector(".previous button");

    let submitButton = wizard.querySelector(".submit-app button");
    let errorModal = new window.bootstrap.Modal(
      document.getElementById("error-modal")
    );

    let count = 0;

    const validatePattern = (pattern, value) => {
      const regexPattern = new RegExp(pattern);
      return regexPattern.test(String(value).toLowerCase());
    };

    prevButton.classList.add("d-none");
    submitButton.classList.add("d-none");

    submitButton.addEventListener("click", () => {
      if (
        // Tab 4
        count === 3 &&
        !(choiceDisclosure.checked === true && fcraDisclosure.checked === true)
        // && form.className.includes('needs-validation')
        // ** NOTE: removed needs-validation class b/c form not used for now **
      ) {
        console.log("modal should show here");
        console.log(`count is ${count}`);
        errorModal.show();
      } else {
        console.log("Submitting form...");
      }
    });

    // on button click tab change
    nextButton.addEventListener("click", () => {
      let requirementsSSN = !(
        inputSSN.value && validatePattern(ssnPattern, inputSSN.value)
      );
      let requirementsConfirmSSN = !(
        inputConfirmSSN.value && inputConfirmSSN.value === inputSSN.value
      );

      // TODO: Highlight field when not valid, add restrictions, etc.
      // let requirementsIssuanceDate = !(
      //   inputIssuanceDate.value &&
      //   validatePattern(issuanceDatePattern, inputIssuanceDate.value)
      // );

      if (
        // Tab 1
        (count === 0 && (requirementsSSN || requirementsConfirmSSN)) ||
        !birthMonth.value ||
        !birthDay.value ||
        !birthYear.value ||
        !firstName.value ||
        !lastName.value
        //!inputExpirationDate.value ||
        //(!inputIssuanceDate.value &&
        //  formSelfId.className.includes("needs-validation"))
      ) {
        formSelfId.classList.add("was-validated");
      } else if (
        // Tab 2
        count === 1 &&
        !(
          inputEmail.value && validatePattern(emailPattern, inputEmail.value)
        ) &&
        formContactInfo.className.includes("needs-validation")
      ) {
        formContactInfo.classList.add("was-validated");
      } else if (
        // Tab 3
        count === 2 &&
        !(
          grossIncomeField.value &&
          purchaseCostField.value &&
          serviceDateField.value
        ) &&
        formPurchaseInfo.className.includes("needs-validation")
      ) {
        formPurchaseInfo.classList.add("was-validated");
      } else {
        count = count + 1;
        const tab = new window.bootstrap.Tab(tabToggleButtonEl[count]);
        tab.show();
      }
    });

    prevButton.addEventListener("click", () => {
      count = count - 1;
      const tab = new window.bootstrap.Tab(tabToggleButtonEl[count]);
      tab.show();
    });

    if (tabToggleButtonEl.length) {
      tabToggleButtonEl.forEach((item, index) => {
        item.addEventListener("show.bs.tab", (e) => {
          if (
            (!inputSSN.value && validatePattern(ssnPattern, inputSSN.value)) ||
            (!inputConfirmSSN.value &&
              validatePattern(confirmSSNPattern, inputConfirmSSN.value) &&
              formSelfId.className.includes("needs-validation"))
          ) {
            e.preventDefault();
            formSelfId.classList.add("was-validated");
            return null;
          }

          count = index;
          // add done class
          for (let i = 0; i < count; i = i + 1) {
            tabToggleButtonEl[i].classList.add("done");
          }

          //remove done class
          for (let j = count; j < tabToggleButtonEl.length; j = j + 1) {
            tabToggleButtonEl[j].classList.remove("done");
          }

          // card footer remove at last step
          if (count > tabToggleButtonEl.length - 2) {
            item.classList.add("done");
            nextButtonParent.remove();
            submitButton.classList.remove("d-none");
          } else {
            nextButton.classList.remove("d-none");
            submitButton.classList.add("d-none");
          }

          // prev-button removing
          if (count > 0 && count < 3) {
            prevButton.classList.remove("d-none");
          } else {
            prevButton.classList.add("d-none");
          }
        });
      });
    }
  });

  // control wizard progressbar
  if (tabPillEl.length) {
    const dividedProgressbar = 100 / tabPillEl.length;
    tabProgressBar.querySelector(".progress-bar").style.width =
      dividedProgressbar + "%";

    tabPillEl.forEach((item, index) => {
      item.addEventListener("show.bs.tab", () => {
        tabProgressBar.querySelector(".progress-bar").style.width =
          dividedProgressbar * (index + 1) + "%";
      });
    });
  }
};

export default wizardInit;
